import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import {
	Flex,
	Heading,
	Paragraph,
} from 'reakit'

import { Container, Sidebar, Body, Footer, Modal, Form } from '../../../components/layouts'

import {
	StatCard,
	DynamicTable,
	Card,
	Loader,
	DateRange,
	Button,
	CheckBox,
	Image,
} from '../../../components/ui'

import {
	refreshToken, getInvoices, getDonations, requestMoney
} from '../../../factory'

import currencyFormat from '../../../util/currencyFormat'
import parseDate from '../../../util/parseDate'

import Layout from '../../../components/layout'

import theme from '../../../theme/content'

class IndexPage extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			bodySize: 0,
			filter: false,
			filterModal: false,
			amount: "$0"
		}

		this.getBodyWidth = this.getBodyWidth.bind(this)
		this.changeDates = this.changeDates.bind(this)
		this.filterDonations = this.filterDonations.bind(this)
		this.validate = this.validate.bind(this)
		this.requestMoney = this.requestMoney.bind(this)
	}

	componentDidMount() {
		this.props.refreshToken().then((res)=> {
			this.props.getInvoices(res)
			this.props.onGetDonations(this.props.organization.id, res)
		})

		this.getBodyWidth()


		window.addEventListener('resize', this.getBodyWidth.bind(this))
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.getBodyWidth.bind(this))
	}

	getBodyWidth() {
		this.setState({
			bodySize: (window.innerWidth - 280)
		})
	}

	changeDates() {
		this.setState(prevState => {filter: !prevState.filter})
	}

	filterDonations(receipt) {
 		var dateValid = true

		const {startDate, endDate} = this.props.stats

		// TODO implement filter for receipts
		if (this.state.filter && !(startDate.valueOf() <= Date.parse(receipt.start_date) && Date.parse(receipt.end_date) <= endDate.valueOf())) {
			dateValid = false
		}

		return dateValid && receipt
	}

	addDonations(a, b, i, cumSum) {
		const val = a + (b.sum || 0)
		cumSum[i] = val
		return val
	}

	validate() {
		var val = parseFloat(this.state.amount.replace("$", ''))
		if (val === null) {
			this.setState({error: `Please make sure you entered only numeric characters.`})
			return false
		}
		var cumSum = []
		this.props.donations.filter(donation => donation.invoice_id === null).reduce((a, b, i) => this.addDonations(a, b, i, cumSum), 0)

		if(val === 0 ) {
			this.setState({error: `Please enter an amount greater than $0.`})
			return false
		}


		if(val > cumSum[cumSum.length - 1]) {
			this.setState({error: `Please enter an amount no greater than $${Math.round(cumSum[cumSum.length - 1] * 100) / 100}.`})
			return false
		}

		if (cumSum.includes(val)) {
			return true
		}

		cumSum.push(val)
		cumSum.sort()

		const idx = cumSum.indexOf(val)
		var lowIdx = 0
		var highIdx = cumSum.length - 1
		if (idx !== lowIdx) {
			lowIdx = idx - 1
		}
		if (idx !== highIdx) {
			highIdx = idx + 1
		}
		this.setState({error: `Please enter either $${Math.round(cumSum[lowIdx] * 100) / 100} or $${Math.round(cumSum[highIdx] * 100) / 100} for your withdrawl amount. It rounds your requested amount to the nearest donation.`})
		return false
	}

	requestMoney() {
		this.props.requestMoney(this.props.organization.title, this.props.admin, this.state.amount)
	}

	renderRequestMoney() {
		const {amount, donations} = this.props


		if (amount) {
			return (
				<Flex row justifyContent="space-between">
					<Heading as="h4">You have successuflly requested ${amount}. Please expect an email from us in the next 3-5 business days confirming the distribution.</Heading>
				</Flex>
			)
		}

		var cumSum = []
		donations.filter(donation => donation.invoice_id === null && donation.payment_state < 3).reduce((a, b, i) => this.addDonations(a, b, i, cumSum), 0)
		const availDonations = cumSum[cumSum.length - 1] ? cumSum[cumSum.length - 1].toFixed(2) : 0.00

		return (
			<div>
				<Flex row justifyContent="space-between">
					<Heading as="h4">Request Money</Heading>
				</Flex>
				<Heading as="h5">Availible Donations: ${availDonations}</Heading>
				<Heading as="h5">How much would you like to recieve?</Heading>
				<Form
					validate={this.validate}
					fields={[[{
						label: 'Requested Distribution',
						name: 'amount',
						placeholder: `$${availDonations}`,
						field: 'default',
						type: 'text',
						maxWidth: '33.33%',
						required: true,
						style: {
							background: 'inherit',
						},
					}]]}
					setValues={(e) => {
						this.setState({amount: e.target.value})
					}}
					values={this.state}
					submitBtnText={'Request'}
					error={this.state.error || this.props.error}
					logging={this.props.pending}
					submitForm={this.requestMoney}
				/>
			</div>
		)
	}

	render() {
		const {receipts, pending, error, requestError, donations} = this.props
		var cumSum = []
		donations.filter(donation => donation.invoice_id === null && donation.payment_state < 3).reduce((a, b, i) => this.addDonations(a, b, i, cumSum), 0)
		const availDonations = cumSum[cumSum.length - 1] ? cumSum[cumSum.length - 1].toFixed(2) : 0.00

		if (error) {
			return (
				<Layout>
					<Container>
						<Sidebar content={theme.adminSidebar} />
						<Body>
							<div style={{minHeight: 'calc(100vh - 200px)'}}>
								<Flex row justifyContent="space-around" marginBottom="1em" width="100%">
									<Card padding="1em" minHeight={'100%'}>
										<Container column >
											<Flex row width="100%" justifyContent={'center'} alignItems={"center"}>
												<Heading as="h4" margin="auto 0" marginRight="1em" marginBottom="20px">Uh Oh! We had an error getting your receipts.</Heading>
											</Flex>
											<Flex row justifyContent={'center'} alignItems={"center"} width="100%">
												<Image
													height="250px"
													width="auto"
													src={'https://storage.googleapis.com/resources.flourishchange.com/Marketing/Illustrations/PNGs/Onboarding2%402x.png'}/>
											</Flex>
											<Flex column width="100%" justifyContent={'center'} alignItems={"center"}>
												<Heading as="h4" margin="auto 0" marginRight="1em" marginBottom="20px">Please call (512)516-9979 and let them know the following error occured:</Heading>
												<Paragraph style={{textAlign: 'center', marginRight: 50}}>{error}</Paragraph>
											</Flex>
										</Container>
									</Card>
								</Flex>
							</div>
							<Flex flex="0 0" alignItems="start" marginLeft="-1em" width="calc(100% + 2em)" row>
								<Footer />
							</Flex>
						</Body>
					</Container>
				</Layout>
			)
		}

		if (!pending && receipts.length === 0) {
			return (
				<Layout>
					<Container>
						<Sidebar content={theme.adminSidebar} />
						<Body>
							<div style={{minHeight: 'calc(100vh - 200px)'}}>
								<Flex row justifyContent="space-around" marginBottom="1em" width="100%">
									<Card padding="1em" minHeight={'100%'}>
										<Container column >
											<Flex row width="100%" justifyContent={'center'} alignItems={"center"}>
												<Heading as="h4" margin="auto 0" marginRight="1em" marginBottom="20px">You don't have any receipts yet...</Heading>
											</Flex>
											<Flex row justifyContent={'center'} alignItems={"center"} width="100%">
												<Image
													height="250px"
													width="auto"
													src={'https://storage.googleapis.com/resources.flourishchange.com/Marketing/Illustrations/PNGs/Onboarding2%402x.png'}/>
											</Flex>
											{this.props.organization && this.props.organization.donor_qty ?
												<Flex column width="100%" justifyContent={'center'} alignItems={"center"}>
													<Flex style={{backgroundColor: theme.colors.crusta, padding: '20px 40px'}} row minHeight={'8em'} justifyContent="space-between" width="90%" marginBottom="1em">
														{this.props.organization && <StatCard
															title={'The Who'}
															stat={this.props.organization.donor_qty || 0}
															loading={!this.props.organization.donor_qty && this.props.organization.donor_qty !== 0}
															description={'Total Users'}
															totalCards={2}
															backgroundColor={theme.colors.crusta}
															textColor={'white'}
															statStyle={{color: 'white'}}
															subtitleStyle={{color: 'white'}}
															titleStyle={{color: 'white'}}
														/>}
														{this.props.stats && <StatCard
															title={'The Current Impact'}
															stat={availDonations || 0}
															type={'money'}
															description={'Availible Donations for withdrawl'}
															totalCards={2}
															backgroundColor={theme.colors.crusta}
															textColor={'white'}
														/>}
													</Flex>
													<Modal
														containterStyle={{minWidth: '70%'}}
														button={{text: 'Request Money Now', width: '15em'}}
													>
														{this.renderRequestMoney()}
													</Modal>
													<Paragraph style={{textAlign: 'center', marginRight: 50, marginTop: 40}}>When we send you donations, you will see your receipt history here.</Paragraph>
													<Paragraph>Need some help? Call us at (512)516-9979, or send us an email at <a href="mailto:nonprofits@flourishchange.com">nonprofits@flourishchange.com</a></Paragraph>
												</Flex> :
												<Flex column width="100%" justifyContent={'center'} alignItems={"center"} style={{textAlign: 'center'}}>
													<Heading as="h4" margin="auto 0" marginRight="1em" marginBottom="20px" style={{textAlign: 'center'}}>Your first step is to add some donors to the app.</Heading>
													<Paragraph>Then, when we send you donations, you will see your receipt history here.</Paragraph>
													<Paragraph>Need some help? Call us at (512)516-9979, or send us an email at <a href="mailto:nonprofits@flourishchange.com">nonprofits@flourishchange.com</a></Paragraph>
												</Flex>
											}
										</Container>
									</Card>
								</Flex>
							</div>
							<Flex flex="0 0" alignItems="start" marginLeft="-1em" width="calc(100% + 2em)" row>
								<Footer />
							</Flex>
						</Body>
					</Container>
				</Layout>
			)
		}

		return (
			<Layout>
				<Container>
					<Sidebar content={theme.adminSidebar} />
					<Body>
						<div style={{minHeight: 'calc(100vh - 200px)'}}>
							<Flex row justifyContent="space-around" marginBottom="1em" width="100%">
								<Card padding="1em" minHeight={'100%'}>
									<Flex row justifyContent="space-between">
										<Flex row>
											{pending && <Heading as="h4" margin="auto 0" marginRight="1em">Loading Receipts...</Heading>}
											{!pending && <Heading as="h4" margin="auto 0" marginRight="1em">Your Receipts</Heading>}
											{pending && <Loader/>}
										</Flex>

										<Flex row>
											{receipts && receipts.length > 0 &&	<Modal
												containterStyle={{minWidth: '70%'}}
												button={{text: 'Filter Receipts', width: '10em'}}
											>
												<Flex row justifyContent="space-between">
													<Heading as="h4">Filter Receipts</Heading>
													<Button onClick={()=>this.setState({filter: false, projectFilter: false, selectedProjectArray: []})}>Clear filters</Button>
												</Flex>
												<Heading as="h6">By Date</Heading>
												<Flex row style={{marginTop: 25, paddingRight: 25}}>
													<CheckBox
														label={'All Time'}
														name={"Include Forever"}
														defaultSelected={!this.state.filter}
														onChange={()=>{
															this.setState({filter: !this.state.filter})
														}}
														style={{marginRight: 50, marginTop: 10, marginBottom: 10}}
													/>
													{this.state.filter && <DateRange disabled backgroundColor="white" textColor="black" onChange={()=>this.setState({filter: true})}/>}
												</Flex>
											</Modal>
											}
											{!pending &&	donations.length > 0 && <Modal
												containterStyle={{minWidth: '70%'}}
												button={{text: 'Request Money', width: '15em', marginLeft: '2em'}}
											>
												{this.renderRequestMoney()}
											</Modal>}
										</Flex>
									</Flex>
									{requestError &&
										<Flex row justifyContent="space-between">
											<Heading as="h4" color={theme.colors.crusta}>{requestError}</Heading>
										</Flex>
									}
									<DynamicTable data={receipts.filter(this.filterDonations)}
										columns={[
											{ Header: 'Id', accessor: 'id', width: 75},
											{ Header: 'Period Start Date', accessor: 'start_date', width: 200, Cell: props => parseDate(props.value),
												sortMethod: (a, b) => {
													return Date.parse(a) > Date.parse(b) ? 1 : -1
												}
										 },
										 { Header: 'Period End Date', accessor: 'end_date', width: 200, Cell: props => parseDate(props.value),
											 sortMethod: (a, b) => {
												 return Date.parse(a) > Date.parse(b) ? 1 : -1
											 }
											},
											{ Header: 'Amount', accessor: 'amount', Cell: props => currencyFormat(props.value) },
											{ Header: '# Donors', accessor: 'donor_count'},
											{ Header: 'Download Link', accessor: 'invoice_url', Cell: props => <a href={props.value}>Download</a>},
										]}
										sortBy={[
											{
												id: "id",
												desc: true
											}
										]}
										paginate
										maxRows={20}
									/>
								</Card>
							</Flex>
						</div>
						<Flex flex="0 0" alignItems="start" marginLeft="-1em" width="calc(100% + 2em)" row>
							<Footer />
						</Flex>
					</Body>
				</Container>
			</Layout>
		)
	}
}

IndexPage.propTypes = {
	organization: PropTypes.object,
	token: PropTypes.string,
	pending: PropTypes.bool,
	refreshToken: PropTypes.func,
	error: PropTypes.string,
	receipts: PropTypes.array,
	getInvoices: PropTypes.func,
	donations: PropTypes.array,
	stats: PropTypes.object,
	onGetDonations: PropTypes.func,
	admin: PropTypes.object,
	requestMoney: PropTypes.func,
	requestError: PropTypes.string,
	amount: PropTypes.float
}

const mapStateToProps = (state) => {
	const {accountingState} = state

	return {
		admin: state.authState.user,
		organization: state.authState.adminOrg || state.adminState.organization,
		token: state.authState.accessToken,
		pending: accountingState.pending,
		receipts: accountingState.invoices,
		error: accountingState.error,
		amount: accountingState.amount,
		requestError: accountingState.request_error,
		donations: state.adminState.donations,
		stats: state.statistics,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		refreshToken: () => dispatch(refreshToken()),
		getInvoices: (token) => dispatch(getInvoices(token)),
		onGetDonations: (org_id, token) => dispatch(getDonations(org_id, token)),
		requestMoney: (org, admin, amount) => dispatch(requestMoney(org, admin, amount)),
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(IndexPage)
